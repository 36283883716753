.error {
  color: red;
  font-size: 10px;
}
.imagesDel {
  margin-top: 1.5em;
  display: flex;
  gap: 25px;
  .imgItems {
    position: relative;
    img {
      width: 84px;
      height: 52px;
      border-radius: 6px;
    }
    video {
      width: 84px;
      height: 52px;
      border-radius: 6px;
    }
    .cross {
      position: absolute;
      right: -11px;
      top: -10px;
    }
    a {
      position: absolute;
      right: -11px;
      top: -10px;
    }
  }
}
.tag-input {
  height: 2.1rem;
  outline: none !important;
  border: none !important;
  &:focus {
    outline: none !important;
  }
}
.rti--container {
  --rti-bg: "#fff";
  --rti-border: #dbdfe9;
  --rti-main: #dbdfe9 !important;
  --rti-radius: "0.375rem";
  --rti-s: "0.5rem";
  --rti-tag: "#edf2f7";
  --rti-tag-remove: "#e53e3e";
}
.card-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 300px;
  height: 175px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .video-preview {
    position: relative;
    .delete-icon {
      position: absolute;
      right: -3px;
      top: -3px;
      cursor: pointer;
    }
  }
}
.file-name {
  margin-top: 10px;
  color: #333;
}
// .wrapper {
//   border: 3px solid blue;
// }

.barCompleted {
  div {
    background-color: #fb8624;
  }
}
.barContainer {
  width: 300px;
  border: 1px solid gray;
  border-radius: 10px;
  background-color: #dbdfe9;
  div {
    background: #fb8624 !important;
  }
}
.primary-color{
  color:#fb923a
}
.pointer{
  cursor: pointer;
}

.label {
  font-size: 20px;
  color: green;
}
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__year-text {
  font-size: 14px; /* Example font size */
}
.PhoneInputInput{
  border: none;
  outline: none;
  background-color: transparent;
  color: gray;
}
.position-relative {
  position: relative;
}
.absIcon {
  position: absolute;
  right: 0.6em;
  font-size: 1.5em;

  color: #dadada;
  &.absIcon1 {
    top: 1.25em;
    &.eye {
      cursor: pointer;
    }
  }
}