// React vendors
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~animate.css/animate.css";
@import "~nouislider/dist/nouislider.css";

#root {
  display: contents;
}

.display-linebreak {
  white-space: pre-line;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
